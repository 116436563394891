import React from 'react'
import { Link } from 'gatsby'
import { StaticQuery } from 'gatsby'
import styled from '@emotion/styled';


const NavWrapper = styled.div`
    position: relative;
    padding-top: 2rem;
    margin: auto;
    max-width: 1400px;
    `
const StyledLink = styled(Link)`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 0.5rem;
    color: white;
    text-shadow: 0 0 20px rgba(255, 255, 255, 0.8), 1px 2px rgba(242, 47, 70, 0.9), -2px -1px rgba(0, 255, 255, 0.5);
    &:hover{
        animation: glitch 100ms 1 alternate;
    }
    @include breakpoint(medium) {
		font-size: 14pt;
	}
`;

const Navigation = () => {
    return (
        <NavWrapper>
            <StyledLink to="/">
                BLOG
            </StyledLink>
            <StyledLink to="/about">
                ABOUT
            </StyledLink>
        </NavWrapper>
    )
}

export default Navigation;