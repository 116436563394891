import styled from '@emotion/styled'
import { graphql } from 'gatsby'
import React from 'react'
import background from '../assets/images/stars.png'
import Header from '../components/header'
import Layout from '../components/layout'
import Navigation from '../components/nav'
import PostList from '../components/post-list'

const PostWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 4rem auto 1rem auto;
  max-width: 1400px;
  font-family: 'Courier New';

  @media (max-width: 1400px) {
    margin: 4rem 4rem 1rem 4rem;
  }
  @media (max-width: 1000px) {
    margin: 4rem 2rem 1rem 2rem;
  }
  @media (max-width: 700px) {
    margin: 4rem 1rem 1rem 1rem;
  }
`

const HeaderWrapper = styled.div`
  &:before {
    background: repeating-linear-gradient(
      #0d122b,
      #0d122b 2px,
      #565b73 2px,
      #565b73 4px
    );
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    opacity: 0.2;
    z-index: 100;
    content: '';
    pointer-events: none;
  }
`

const Background = styled.div`
  position: fixed;
  height: 100vh;
  width: 100%;
  z-index: 0;
  /* background from https://imgur.com/gallery/SELjK */
  background-image: url(${background});
  background-attachment: fixed;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
`

const IndexPage = ({ data }) => {
  const { edges } = data.allMarkdownRemark
  return (
    <Layout>
      <Background className="load" />
      <HeaderWrapper>
        <Navigation />
        <Header />
      </HeaderWrapper>
      <PostWrapper>
        {edges.map(({ node }) => (
          <PostList
            key={node.id}
            cover={node.frontmatter.cover.childImageSharp.fluid}
            path={node.frontmatter.path}
            title={node.frontmatter.title}
            date={node.frontmatter.date}
            excerpt={node.excerpt}
          />
        ))}
      </PostWrapper>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query {
    allMarkdownRemark(
      limit: 20
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { published: { ne: false } } }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 75)
          frontmatter {
            title
            path
            tags
            date(formatString: "MM.DD.YYYY")
            cover {
              childImageSharp {
                fluid(
                  maxWidth: 1000
                  quality: 90
                  traceSVG: { color: "#2B2B2F" }
                ) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
        }
      }
    }
  }
`
