import styled from '@emotion/styled';
import React from 'react';

const Title = styled.div`
  margin: auto;
  font-family: 'Permanent Marker';
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  line-height: 0.75;
  word-break: break-word;
  max-width: 500px;
  animation: none;
  filter: brightness(1.2) saturate(1.2);
  height: 100%;
`
const H1 = styled.h1`
  background: -webkit-linear-gradient(rgb(255, 18, 18) 30%, rgb(255, 215, 38));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 3px 3px 20px rgba(255, 224, 0, 0.18),
    -1px 5px 20px rgba(255, 0, 30, 0.15), -1px -2px 6px rgba(0, 255, 255, 0.18);
`

const Header = () => (
  <section className="splash-screen textLoad">
    <Title className="title">
      <H1 className="title-top">
        <span className="big">C</span>
        ode
      </H1>
      <H1 className="title-bottom">
        <span className="big">V</span>
        agabon
        <span className="big">d</span>
      </H1>
    </Title>
  </section>
)

export default Header
